import Hermes from 'plugins/Hermes';
import bus    from 'src/Components/bus';

export default {
  /**
   * Return the sample version
   * @param {Function} cb : callback
   */
  getSample(cb) {
    Hermes.run({
      route : 'version.sample'
    }, (data) => {
      cb(data);
    });
  },

  uploadTemplate (idVersion, data, cb) {
    Hermes.run({
      route  : 'version.uploadTemplate',
      params : {
        idVersion : idVersion
      },
      headers : {
        Accept : 'application/json'
      },
      data : data
    }, (data) => {
      cb(data);
    });
  },

  /**
   * Download a template
   * @param {String} filename Filename to download
   * @param {Function} callback
   */
  downloadTemplate (filename, callback) {
    const regexp  = /filename="(.*)"/gi;
    let options   = Hermes._options;
    let url       = `${options.protocol}//${options.baseUrl}:${options.port}/templates/${filename}/download`;
    let templateName = '';

    fetch(url, {
      method      : 'GET',
      credentials : 'same-origin'
    }).then((response) => {
      if (response.status === 404) {
        return callback(new Error('File not found'));
      } else if (response.status === 403) {
        return callback(new Error('Unauthorized access'));
      } else if (response.status === 200) {
        const contentDisposition = response.headers.get('content-disposition');
        templateName = regexp.exec(contentDisposition)[1];

        return response.blob();
      }

      return callback(new Error(`An error occured: ${response.statusText}`))
    }).then(function (file) {
      return callback(null, {
        content      : file,
        templateName : templateName
      });
    });
  },

  /**
   * Return all informations of a report
   * @param {String} id : ID of the report
   * @param {Function} cb : callback
   */
  getFullReport(id, cb) {
    Hermes.run({
      route: 'report.getFullReport',
      params: {
        id: id
      }
    }, (data) => {
      cb(data);
    });
  },

  /**
   * Delete a report by ID
   * @param {String} idReport : ID of a report
   * @param {Function} cb : callback
   */
  deleteReport(idReport, cb) {
    Hermes.run({
      route  : 'report.deleteReport',
      params : {
        id : idReport
      }
    }, (data) => {
      cb(data)
    });
  },

  /**
   * Return all versions of a report
   * @param {String} idReport : ID of the report
   * @param {Function} cb : callback
   */
  getVersions(idReport, cb) {
    Hermes.run({
      route  : 'report.getVersions',
      params : {
        id : idReport
      }
    }, (data) => {
      cb(data);
    }, (error) => {
      console.log('Error', error.message);
    });
  },

  /**
   * Return informations of a report (category...)
   * @param {String} idReport : ID of the report
   * @param {Function} cb : callback
   */
  getReportInfos(idReport, cb) {
    Hermes.run({
      route  : 'report.getOneReport',
      params : {
        id : idReport
      }
    }, (data) => {
      cb(data);
    }, (error) => {
      bus.$emit('toast', error.message, 'error');
    });
  },

  /**
   * Render a version
   * @param {String} idVersion : ID of a version
   * @param {Object} data : Data for rendergin (data, complement, enum, convertTo...)
   * @param {Function} cb : callback
   */
  render(idVersion, data, carboneVersion, cb) {
    Hermes.run({
      route  : 'report.render',
      params : {
        id : idVersion
      },
      headers : {
        'carbone-version' : carboneVersion,
        Accept            : 'application/json',
        'Content-Type'    : 'application/json'
      },
      data : data
    }, (res) => {
      cb(res);
    });
  },

  /**
   * Return stats of a version (number of generation, logs)
   * @param {String} idVersion : ID of a version
   * @param {Function} cb : callback
   */
  getStats(idVersion, cb) {
    Hermes.run({
      route  : 'version.getStatsVersion',
      params : {
        idVersion : idVersion
      }
    }, (stats) => {
      cb(stats);
    });
  },

  /**
   * Add a report
   * @param {Form} formData : the form with the data and the file to add
   * @param {Function} cb : callback
   */
  addReport(formData, cb) {
    Hermes.run({
      route   : 'report.addReport',
      headers : {
        Accept : 'application/json'
      },
      data : formData
    }, (data) => {
      cb(data);
    });
  },

  /**
   * Update a report
   * @param {String} idVersion : ID of a version
   * @param {Object} data : data to update (version number, description...)
   * @param {Function} cb : callback
   */
  updateVersion (idVersion, data, cb) {
    Hermes.run({
      route  : 'version.updateVersion',
      data   : data,
      params : {
        id : idVersion
      }
    }, (data) => {
      cb(data);
    });
  },

  /**
   * Add a version to a report
   * @param {String} idReport : ID of a report
   * @param {Object} data : Data to add
   * @param {Function} cb : callback
   */
  addVersion(idReport, data, cb) {
    Hermes.run({
      route  : 'report.addVersion',
      params : {
        id : idReport
      },
      headers : {
        Accept : 'application/json'
      },
      data : data
    }, (data) => {
      cb(data);
    });
  },

  /**
   * Save a version
   * @param {String} idVersion : ID of a version
   * @param {Object} data : Data to update
   * @param {Function} cb : callback
   */
  saveVersion (idVersion, data, cb) {
    Hermes.run({
      route  : 'version.saveVersion',
      params : {
        id : idVersion
      },
      data : data
    }, (data) => {
      cb(data);
    });
  },

  /**
   * Save as a new version
   * @param {String} idVersion : ID of the version
   * @param {Object} data : data to save
   */
  saveAs (idVersion, data, cb) {
    Hermes.run({
      route  : 'version.saveAsVersion',
      params : {
        id : idVersion
      },
      data : data
    }, (data) => {
      cb(data);
    });
  },
  
  /**
   * Privatize a version
   * @param {String} idVersion : ID of a version
   * @param {Function} cb : callback
   */
  privatizeVersion (idVersion, cb) {
    Hermes.run({
      route  : 'version.privatizeVersion',
      params : {
        id : idVersion
      }
    }, (data) => {
      cb(data);
    });
  },

  /**
   * Get a shared version by key
   * @param {String} key : Share key of the version
   * @param {Function} cb
   */
  getSharedVersion (key, cb) {
    Hermes.run({
      route  : 'version.getVersionByKey',
      params : {
        key : key
      }
    }, (data) => {
      cb(data);
    });
  },

  /**
   * Duplicate a shared version to get it
   * @param {String} idVersion : ID of the version
   * @param {Object} data : Data like reportName, filename
   * @param {Function} cb
   */
  duplicateVersion (idVersion, data, cb) {
    Hermes.run({
      route  : 'version.duplicateVersion',
      params : {
        id : idVersion
      },
      data : data
    }, (data) => {
      cb(data);
    });
  },

  /**
   * Update the report informations
   * @param {Integer} idReport : ID of the report to update
   * @param {Object} data : New informations
   * @param {Function} cb
   */
  updateReport (idReport, data, cb) {
    Hermes.run({
      route  : 'report.updateReport',
      params : {
        id : idReport
      },
      data : data
    }, (res) => {
      cb(res);
    });
  }
}
