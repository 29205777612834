<template>
  <div id="toolbar">
    <md-toolbar class="primary md-dense" style="display:flex;align-items: center;">
      <md-button class="md-icon-button" @click="toggleLeftSidenav">
        <md-icon>menu</md-icon>
        <md-tooltip md-direction="right"><b>Templates</b></md-tooltip>
      </md-button>

      <div v-if="isHidden === false" style="display: flex;flex:1;align-items:center;flex-direction: row;">
        <span style="font-weight: bold;">
          <span style="color: #ffffff63;text-transform: uppercase;" v-if="current.category && current.category.name">{{ current.category.name }} /</span>
          <span style="color: #ffffff63;font-size:1.1em;margin-right:0px !important; margin-left:2px;">{{ current.report.originalFileName }}</span>
          <span v-if="ext" style="text-transform: uppercase;background-color: #673ab7;color: #ffffff63;">.{{ ext }}</span>
          <span style="color: #ffffff63;margin-left:5px">/ V{{ current.version.versionNumber }}</span>
        </span>       

        <span style="margin-left: 40px;display: flex;flex-direction: row;align-items: center;">
          <md-button class="md-dense" style="margin:0;line-height:0!important;display: flex;align-items: center;" @click="toggleRightSidenav" >
              <md-icon style="font-size:20px;margin-right: 5px;margin-top:5px">timeline</md-icon><b>Versions</b>
          </md-button> 
          <span style="position:relative; line-height: 0;">
            <md-button :class="{ 'md-dense': true, shake : templateIdUpdated }" style="margin:0;margin-left: 10px;" @click="copyTemplateId"  v-if="idFileServer">
              <b>ID: {{ templateId }}</b>
              <md-tooltip md-direction="bottom"><b style="font-size:13px;">Copy Template ID</b></md-tooltip>
            </md-button>
            <span :class="{ show: templateIdUpdated }" style="transition:all;position: absolute;top: 35px;left: -17px;background-color: #a544c5;padding-left: 20px;line-height: 25px;width: 190px;text-align: center;border-radius: 15px;font-weight: 800;opacity: 0;"><md-icon style="width: 20px;font-size: 23px;position: absolute;left: 2px;top: 1px;">info</md-icon>Template ID updated!</span>
          </span>
          <span style="display:flex">
            <md-button class="md-dense" :disabled="this.liveReloadEnabled === true" style="margin:0;margin-left: 10px;" @click="dlTemplate"><md-icon style="font-size:20px;margin-right: 5px;">cloud_download</md-icon><b>Download</b><md-tooltip md-direction="bottom"><b style="font-size:13px;">Download current template for live-reload ⚡️</b></md-tooltip></md-button>
            <md-button class="md-dense" :disabled="this.liveReloadEnabled === true" style="margin:0;margin-left: 10px;" @click="upTemplate"><md-icon style="font-size:20px;margin-right: 5px;">cloud_upload</md-icon><b>Upload</b><md-tooltip md-direction="bottom"><b style="font-size:13px;">Upload local template for live-reload ⚡️</b></md-tooltip></md-button>
            <md-tooltip md-direction="bottom" v-if="this.liveReloadEnabled === true"><b style="font-size:13px;">Stop Live-reload to download or upload</b></md-tooltip>
          </span>
          <span class="gradient-border" style="display: flex;border-radius: 25px;margin-left:10px;"  v-if="this.liveReloadEnabled === true">
            <md-button class="md-icon-button" @click="openArticleLiveReload" style="margin: 0;">
              <md-icon>sync</md-icon>
            </md-button>
            <md-button class="md-icon-button" @click="stopLiveReload" style="margin: 0;">
              <md-icon>cancel</md-icon>
              
            </md-button>
            <md-tooltip md-direction="bottom"><b style="font-size:13px;">Live-reload activated</b></md-tooltip>
            <md-tooltip md-direction="right"><b style="font-size:13px;">Stop Live-reload</b></md-tooltip>
          </span>
        </span>
      </div>
      <div  v-if="isHidden === true" style="flex: 1; text-align: center; font-weight: 500; font-size: 13pt;">
        Carbone Studio
      </div>
      <span style="display:flex;position:relative" v-if="isHidden === false">
        <md-button  :class="{ 'md-icon-button': true, shake : tutorialIndicator }" @click="openTutorial" >
          <md-icon >play_circle</md-icon>
          <md-tooltip md-direction="bottom"><b style="font-size:13px;">Studio Tutorials</b></md-tooltip>
        </md-button>
        <span :class="{ show: tutorialIndicator }" style="transition:all;position: absolute;top: 35px;left: -50px;background-color: #a544c5;padding-left: 20px;line-height: 25px;width: 150px;text-align: center;border-radius: 15px;font-weight: 800;opacity: 0;"><md-icon style="width: 20px;font-size: 23px;position: absolute;left: 2px;top: 1px;">info</md-icon>Studio Tutorial</span>
      </span>
      <!-- <md-button class="md-icon-button" v-if="isHidden === false">
        <md-icon >science</md-icon>
        <md-tooltip md-direction="bottom"><b style="font-size:13px;">Try Studio Nocode (Alpha)</b></md-tooltip>
      </md-button> -->
      <md-button class="md-icon-button" v-if="isHidden === false" @click="writeReviewG2">
        <md-icon style="font-size:23px">featured_seasonal_and_gifts</md-icon>
        <md-tooltip style="height:35px;line-height: 16px;" md-direction="bottom"><b style="font-size:13px">Review Carbone,<br>Get Amazon Gift Card!</b></md-tooltip>
      </md-button>
      <c-menu style="margin-right: 10px;margin-left: 20px;" :email="this.user.email" :emailhash="this.user.emailHash" :firstname="this.user.firstname" :lastname="this.user.lastname" :user="this.user.studioOnly" :accounturl="this.user.accounturl" />
    </md-toolbar>
  </div>
</template>

<style>
  .category-name::after {
    content: '' !important;
  }
  .version-name::after {
    content: '' !important;
  }

  .shake {
    animation: horizontal-shaking 1s ease-in-out;
    animation-iteration-count: 2
  }

  @keyframes horizontal-shaking {
    0% { transform: translateX(3px) }
    50% { transform: translateX(-3px) }
    100% { transform: translateX(3px) }
  }

  @keyframes opacity {
    0% { opacity: 0; }
    10% { opacity: 1; }
    95% { opacity: 1; }
    100% { opacity: 0; }
  }

  .show {
    animation: opacity 3s ease-out;
    animation-iteration-count: 1;
  }

.gradient-border {
  --borderWidth: 3px;
  background: #673ab7;
  position: relative;
  border-radius: 25px;
}
.gradient-border:after {
  content: '';
  position: absolute;
  top: calc(-1 * var(--borderWidth));
  left: calc(-1 * var(--borderWidth));
  height: calc(100% + var(--borderWidth) * 2);
  width: calc(100% + var(--borderWidth) * 2);
  background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
  border-radius: 25px;
  z-index: -1;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 200% 200%;
}


@keyframes animatedgradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

</style>

<script>

import bus                        from 'src/Components/bus';
import { mapGetters, mapActions } from 'vuex';
import config         from '../../config';
import mimetypes      from './mimetype'

export default {
  components : {},
  data () {
    return {
      user : {},
      templateIdUpdated: false,
      fileHandle: null,
      fileContent: null,
      fileWatchInterval: null,
      fileFirstLoad: false,
      tutorialIndicator: false
    };
  },
  computed : {
    ...mapGetters({
      idFileServer     : 'idFileServer',
      current          : 'current',
      ext              : 'ext',
      continueRefresh  : 'continueRefresh'
    }),
    isHidden () {
      if (this.current.report === undefined) {
        return true;
      }
      return this.current.report.id === undefined;
    },
    isShared () {
      return this.current.shared;
    },
    hasReport () {
      if (this.current.report === undefined) {
        return false;
      }
      return typeof this.current.report.id !== 'undefined';
    },
    templateId() {
      return this.idFileServer.slice(0, 10) + '...';
    },
    liveReloadEnabled() {
      return 'showSaveFilePicker' in window === true && this.continueRefresh === true
    }
  },
 
  methods : {
    ...mapActions({
      toastInfo  : 'toastInfo',
      toast      : 'toast',
      toastError : 'toastError',
      toastSuccess: 'toastSuccess',
      downloadTemplate  : 'downloadTemplate',
      uploadTemplate      : 'uploadTemplate',
      setContinueRefresh  : 'setContinueRefresh',
    }),
    refresh () {
      bus.$emit('refreshCurrentPreview');
    },
    openTutorial () {
      bus.$emit('tutorial');
    },
    openDuplicate () {
      bus.$emit('duplicateModal', 'open');
    },
    runHelpCode () {
      bus.$emit('infoVersion');
    },
    displayNewReport () {
      bus.$emit('displayAddReport', 'with');
    },
    toggleLeftSidenav () {
      bus.$emit('menu', 'open');
    },
    toggleRightSidenav () {
      bus.$emit('menu-versions', 'open')
    },
    toggleEditReportSidenav () {
      if (this.current.report.id !== undefined && !this.current.shared) {
        bus.$emit('editReportSidenav');
      } else {
        this.toastError('You can\'t edit this report');
      }
    },
    async fetchUser() {
      const res = await fetch(`${config.CARBONE_ACCOUNT_URL}/api/user`, {
          method: "GET",
          credentials: 'include',
          headers    : {
            'Content-Type': 'application/json'
          }
        }).then(async (res) => await res.json());
        if (res.success === true) {
          res.data.accounturl = `${config.CARBONE_ACCOUNT_URL}`;
          res.data.studioOnly += 'p';
          this.user = res.data;
        }
    },
    copyTemplateId() {
      bus.$emit('copyTemplateId');
    },
    stopLiveReload() {
      bus.$emit('reset-live-reload-upload');
      bus.$emit('reset-live-reload-download');
    },
    dlTemplate : async function (e) {

      if ('showSaveFilePicker' in window === false) {
        return this.downloadTemplate({
            filename : this.current.version.id
          }).then(async (file) => {
            // Download the file
            const url      = window.URL.createObjectURL(file.content)
            const link     = document.createElement('a')

            link.href = url
            link.setAttribute('download', file.templateName);
            document.body.appendChild(link)

            link.click()
          }, (msg) => {
            this.toastError(msg);
          });
      }

      const _options = {
        suggestedName: `template_${this.current.report.originalFileName}${this.ext ? '.' + this.ext : ''}`,
        startIn: 'documents',
        types: [
          {
            description: 'Text documents'
          },
        ],
      }
      if (mimetypes.hasOwnProperty(this.ext) === true) {
        _options.types[0].accept = {
          [mimetypes[this.ext].mime] : [`.${this.ext}`]
        }
      }
    
      this.fileHandle = null;
      this.fileFirstLoad = false;
      try {
        this.fileHandle = await window.showSaveFilePicker(_options);
      } catch (err) {
        console.log("Watch File Download Global Error Catched showSaveFilePicker:", err.toString());
        return;
      }

      this.downloadTemplate({
        filename : this.current.version.id
      }).then(async (file) => {
        // Save the file content on the browser
        this.fileContent = await this.fileHandle.getFile();

        // Create a FileSystemWritableFileStream to write to.
        const writable = await this.fileHandle.createWritable();

        // Write the contents of the file to the stream.
        await writable.write(file.content);

        // Close the file and write the contents to disk.
        await writable.close();

        this.watchTemplate();
      }, (msg) => {
        this.toastError(msg);
      });
    },
    upTemplate : function () {
      bus.$emit('ModalUploadTemplate');
    },
    watchTemplate (cb) {
      this.setContinueRefresh(true);
      clearInterval(this.fileWatchInterval);
      this.fileWatchInterval = setInterval(async () => {
        if (!this.fileHandle) {
          bus.$emit('reset-live-reload-download');
          return;
        }
        let _newFile = await this.fileHandle.getFile().catch(err => {
          console.log("Download | Watch local file error catched:", err.toString());
          bus.$emit('reset-live-reload-download');
        });
        if (this.fileFirstLoad === false) {
          this.fileFirstLoad = true;
          this.fileContent = _newFile;
          return;
        }
        if (_newFile && _newFile !== null && 
            _newFile.lastModified !== this.fileContent.lastModified &&
            this.continueRefresh === true &&
            this.fileFirstLoad === true) {
          this.fileContent = _newFile;
          this.uploadNewTemplate(_newFile)
          if (cb && typeof cb === 'function') {
            cb(null, _newFile);
          }
        } else if (_newFile === null) {
          bus.$emit('reset-live-reload-download');
        }
      }, 1000);
    },
    uploadNewTemplate (file) {
      const formData = new FormData();
      formData.append('file', file);

      this.uploadTemplate({
        idVersion : this.current.version.id,
        data      : formData
      }).then(() => {
        // Can refresh locally
        this.setContinueRefresh(true);
        bus.$emit('refreshPreview');
      }, (msg) => {
        this.toastError(msg);
      });
    },
    openArticleLiveReload () {
      const id = "how-to-enable-live-reload-1pd53ln"
      try {
        if (window.$crisp) {
          window.$crisp.push(["do", "helpdesk:article:open", ['en-us', id]])
        } else {
          window.open(`https://help.carbone.io/en-us/article/${id}`, "_blank");
        }
      } catch(err) {
        console.log("Helpdesk article not available: ", err.toString());
      }
    },
    writeReviewG2 () {
      if (window.$crisp) {
        window.$crisp.push(["set", "session:event", ["write-review-carbone-g2"]]);
      } else {
        window.open(`https://www.g2.com/products/carbone/take_survey`, "_blank");
      }
    }
  },
  created () {
    this.fetchUser()
  },
  mounted() {
    bus.$on('template-id-updated', () => {
      this.templateIdUpdated = true;
      setTimeout(() => {
        this.templateIdUpdated = false;
      }, 3200)
    })

    bus.$on('reset-live-reload-download', () => {
      this.setContinueRefresh(false);
      this.fileContent = null;
      this.fileHandle = null;
      this.fileFirstLoad = false;
      clearInterval(this.fileWatchInterval);
    })

    bus.$on('tutorial-closed', () => {
      this.tutorialIndicator = true;
    });
  },
  onUnmounted () {
    bus.$off('template-id-updated');
    bus.$off('reset-live-reload-download');
  },
};
</script>
