<template>
  <div id="sidenav">
    <md-sidenav class="md-left" ref="leftSidenav" @open="opened" @close="closed">
      <md-toolbar class="md-dense">
      <div class="md-toolbar-container">
        <h3 class="md-title" style="font-weight: 600;">
            Templates
        </h3>
        <span style="flex: 1"></span>
        <md-button class="md-icon-button md-dense" @click="closeLeftSideNav"><md-icon>close</md-icon></md-button>
      </div>
      
    </md-toolbar>
      <md-list style="padding-top: 0;">
        <md-list-item @click="addReport">
          <md-icon>add</md-icon><span>New Template</span>
        </md-list-item>                

        <md-list-item  id="search-container" style="z-index: 3">
            <search :list="templateListWithCateg"></search>
        </md-list-item>

        <md-list-item id="categories-container" style="border-top:1px solid #dbdbdb;border-bottom:1px solid #dbdbdb;padding: 0 !important;">
          <toggle-group-by-button :state="displayByCateg"></toggle-group-by-button>
          <span><router-link style="margin:0;color: rgba(0, 0, 0, 0.54);" class="md-button md-theme-default" :to="{ name: 'categoriesManager' }" > <md-icon style="font-size: 20px;">edit</md-icon> Edit Categories </router-link></span>
        </md-list-item>
        <template v-if="displayByCateg">
          <categories-list v-if="templateListWithoutCateg !== null" :reports="templateListWithoutCateg"></categories-list>
        </template>
        <template v-else>
          <reports-list :reports="templateListWithCateg"></reports-list>
        </template>
      </md-list>
    </md-sidenav>
  </div>
</template>

<style>
  #categories-container > .md-list-item-container {
    padding:0 0px;
    min-height: 0;
  }
  #search-container > .md-list-item-container {
    padding:0;
  }
</style>

<script>
import DisplayByCateg              from './DisplayByCateg.vue';
import ToggleGroupByButton         from './SidenavToggleGroupByCateg.vue';
import CategoriesList              from './CategoriesList.vue';
import ReportsList                 from './ReportsList.vue';
import Search                      from './search/BasicSearch.vue';
import bus                         from './bus';
import { mapGetters, mapActions }  from 'vuex';

export default {
  data () {
    return {
      displayByCateg : true
    };
  },
  components : {
    ToggleGroupByButton,
    CategoriesList,
    DisplayByCateg,
    ReportsList,
    Search
  },
  computed : {
    ...mapGetters({
      templateListByCateg      : 'templateListByCateg',
      templateListWithCateg    : 'templateListWithCateg',
      templateListWithoutCateg : 'templateListWithoutCateg'
    })
  },
  methods : {
    ...mapActions({
      getTemplateListWithCateg    : 'getTemplateListWithCateg',
      getTemplateListWithoutCateg : 'getTemplateListWithoutCateg',
      getTemplateListByCateg      : 'getTemplateListByCateg'
    }),
    getCategs () {
      var categs = this.templateListByCateg;
      return (categs);
    },
    updateDisplayByCateg : function () {
      this.displayByCateg = (this.displayByCateg === true) ? false : true;
    },

    /**
     * Refresh list of report in sidenav
     */
    getTemplateList () {
      this.getTemplateListWithCateg();
      this.getTemplateListWithoutCateg();
      this.getTemplateListByCateg();
    },

    /**
     * On open set the focus on the searchbar
     */
    opened () {
      bus.$emit('focusInputSearchReport');
    },

    /**
     * Choose if we display the report list with or without category
     */
    toggleDisplayCategs () {
      this.displayByCateg = !this.displayByCateg;
      if (this.displayByCateg === false) {
        localStorage.setItem('displayCategs', 'false');
      } else {
        localStorage.setItem('displayCategs', 'true');
      }
    },
    closeLeftSideNav() {
      this.$refs.leftSidenav.close();
    },
    addReport () {
      bus.$emit('displayAddReport', false);
    },
  },
  mounted () {
    // Get the value in local storage
    if (localStorage.getItem('displayCategs') === 'true') {
      this.displayByCateg = true;
    } else {
      this.displayByCateg = false;
    }
    bus.$on('menu', (status) => {
      if (typeof this.$refs.leftSidenav !== 'undefined') {
        if (status === 'open') {
          this.$refs.leftSidenav.open();
        } else if (status === 'close') {
          this.$refs.leftSidenav.close();
        } else {
          this.$refs.leftSidenav.toggle();
        }
      }
    });
    bus.$on('closeMenu', () => {
      this.$refs.leftSidenav.close();
    });
    bus.$on('refreshList', () => {
      this.getTemplateList();
    });
    bus.$on('toggleGroupByCateg', () => {
      this.toggleDisplayCategs();
    });
  },
  created () {
    this.getTemplateList();
  }
};
</script>