import * as types from '../mutation-types';
import currentTemplate from '../../api/currentTemplate';
import bus from './../../Components/bus';

const state = {
  current : {
    report      : {},
    version     : {},
    category    : {},
    versions    : [],
    tags        : [],
    file        : null,
    canSave     : false,
    fileContent : null,
    shared      : false
  },

  old : {
    report  : {},
    version : {}
  },
  ext                    : '',
  idFileServer           : '',
  fileNameToPreview      : '',
  fileExtentionInputType : '',
  lang                   : 'en-gb',
  timezone               : 'europe/london',
  currencySource         : 'EURO',
  currencyTarget         : 'EURO',


  /**
   * This is usefull to know when we must refresh the cache
   * At opening modal => stop refresh else when file input is fill, it refresh automatically
   * When closing modal => refresh again
   */
  continueRefresh : false
};

const getters = {
  current           : state => state.current,
  old               : state => state.old,
  idFileServer      : state => state.idFileServer,
  ext               : state => state.ext,
  fileNameToPreview : state => state.fileNameToPreview,
  continueRefresh   : state => state.continueRefresh,
  lang              : state => state.lang || 'fr-fr',
  timezone          : state => state.timezone || 'utc',
  currencySource    : state => state.currencySource || 'EURO',
  currencyTarget    : state => state.currencyTarget || 'EURO'
};

const actions = {
  /**
   * set the sample report in the current
   */
  getSample ({ commit }) {
    return new Promise((resolve, reject) => {
      currentTemplate.getSample((data) => {
        if (data.success !== false && data.data.report !== undefined) {
          commit(types.SET_REPORT, data.data.report);
          commit(types.SET_VERSION, data.data.versions[0]);
          commit(types.SET_ALL_VERSIONS, data.data.versions);
          commit(types.SET_CATEGORY, data.data.category);
          commit(types.SET_ID_FILE_SERVER, state.current.version.filename);
          commit(types.SET_EXT, data.data.versions[0].ext);
          commit(types.SET_DATA_CARBONE, JSON.parse(data.data.versions[0].data));
          commit(types.SET_ENUMS_CARBONE, JSON.parse(data.data.versions[0].enum));
          commit(types.SET_COMPLEMENT_CARBONE, JSON.parse(data.data.versions[0].complement));
          commit(types.SET_TRANSLATIONS, JSON.parse(data.data.versions[0].translations));
          resolve();
        } else {
          reject();
        }
      });
    });
  },

  /**
   * Set the wanted report in the current
   * @param {Integer} idReport : id of the report
   */
  getFullReport ({ commit }, idReport) {
    return new Promise((resolve, reject) => {
      currentTemplate.getFullReport(idReport, (data) => {
        // Order version
        data.data.versions = orderVersions(data.data.versions);
        if (data.success === true) {
          resolve(data.data);
        } else {
          reject();
        }
      });
    });
  },

  /**
   * Set a report in current
   * @param {Object} data : must be a return of the request `getFullReport`
   */
  setInCurrent ({ dispatch, commit }, data) {
    return new Promise((resolve, reject) => {
      dispatch('getIndexByIDVersion', data).then((index) => {
        commit(types.SET_REPORT, data.report);
        commit(types.SET_VERSION, data.versions[index]);
        commit(types.SET_ALL_VERSIONS, data.versions);
        commit(types.SET_CATEGORY, data.category);
        commit(types.SET_ID_FILE_SERVER, state.current.version.filename);
        commit(types.SET_EXT, data.versions[index].ext);
        commit(types.SET_DATA_CARBONE, JSON.parse(data.versions[index].data));
        commit(types.SET_ENUMS_CARBONE, JSON.parse(data.versions[index].enum));
        commit(types.SET_COMPLEMENT_CARBONE, JSON.parse(data.versions[index].complement));
        commit(types.SET_TRANSLATIONS, JSON.parse(data.versions[index].translations));
        commit(types.SET_LAST_MODIFIED_FILE, -1);
        bus.$emit('reset-live-reload-download');
        bus.$emit('reset-live-reload-upload');
        resolve();
      }, () => {
        reject();
      });
    });
  },

  /**
   * Return the index of a version depending on ID
   * @param {Object} data : contain the array of versions and the wanted ID
   */
  getIndexByIDVersion ({ commit }, data) {
    return new Promise((resolve, reject) => {
      for (var i in data.versions) {
        if (data.versions[i].id === data.idVersion) {
          resolve(i);
        }
      }
      reject();
    });
  },

  /**
   * Delete a report
   * @param {String} idReport : ID of a report
   */
  deleteReport ({ commit }, idReport) {
    return new Promise((resolve, reject) => {
      currentTemplate.deleteReport(idReport, (res) => {
        if (res.success === true) {
          resolve(res.message);
        } else {
          reject(res.message);
        }
      });
    });
  },

  /**
   * Return stats of a version
   * @param {String} idVersion : ID of a version
   */
  getStats ({ commit }, idVersion) {
    return new Promise((resolve, reject) => {
      currentTemplate.getStats(idVersion, (res) => {
        resolve(res.data);
      });
    });
  },

  /**
   * Add a report
   * @param {Object} data : data of the form
   */
  addReport ({ commit }, formData) {
    return new Promise((resolve, reject) => {
      currentTemplate.addReport(formData, (res) => {
        if (res.success === true) {
          commit(types.SET_REPORT, res.data.report);
          commit(types.SET_VERSION, res.data.version);
          commit(types.SET_ALL_VERSIONS, [res.data.version]);
          commit(types.SET_CATEGORY, res.data.category);
          commit(types.SET_TAGS, res.data.tags);
          commit(types.SET_ID_FILE_SERVER, state.current.version.filename);
          commit(types.SET_EXT, res.data.version.ext);
          commit(types.SET_DATA_CARBONE, JSON.parse(res.data.version.data));
          commit(types.SET_ENUMS_CARBONE, JSON.parse(res.data.version.enum));
          commit(types.SET_COMPLEMENT_CARBONE, JSON.parse(res.data.version.complement));
          commit(types.SET_TRANSLATIONS, JSON.parse(res.data.version.translations));
          resolve(res.message);
        } else {
          reject(res.message);
        }
      });
    });
  },

  /**
   * Update informations of a version
   * @param {Object} data : contain id and new data
   */
  updateVersion ({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      currentTemplate.updateVersion(data.id, data.data, (res) => {
        if (res.success === true) {
          // If current version is the updated one, update it in store
          if (state.current.version.id === res.data.version.id) {
            commit(types.SET_VERSION, res.data.version);
          }

          // Update version in list of versions
          commit(types.UPDATE_ONE_VERSION_IN_LIST, res.data.version);
          resolve(res.message);
        } else {
          reject(res.message);
        }
      });
    });
  },

  /**
   * Save version
   * @param {Object} data : data to save (data, complement enum, cache version to keep)
   */
  saveVersion ({ commit, state, dispatch }, data) {
    return new Promise((resolve, reject) => {
      currentTemplate.saveVersion(data.id, data.data, (res) => {
        if (res.success === true) {
          commit(types.SET_CAN_SAVE, false);
          dispatch('getIndexByIDVersion', {
            idVersion : res.data.version.id,
            versions  : state.current.versions
          }).then((index) => {
            var newVersions = state.current.versions;
            newVersions[index] = res.data.version;
            commit(types.SET_ALL_VERSIONS, newVersions);
            commit(types.SET_VERSION, res.data.version);
            commit(types.SET_ID_FILE_SERVER, state.current.version.filename);
            resolve(res.message);
          }, () => {
            reject(null);
          });
        } else {
          reject(res.message);
        }
      });
    });
  },

  /**
   * Save a new version
   * @param {Object} data : data to save a new version
   */
  saveAsVersion ({ commit }, data) {
    return new Promise((resolve, reject) => {
      currentTemplate.saveAs(data.idVersion, data.data, (res) => {
        if (res.success === true) {
          commit(types.SET_CAN_SAVE, false);
          commit(types.SET_VERSION, res.data.version);
          commit(types.PUSH_VERSION, res.data.version);
          commit(types.SET_ID_FILE_SERVER, state.current.version.filename);
          commit(types.SET_EXT, res.data.version.ext);
          commit(types.SET_DATA_CARBONE, JSON.parse(res.data.version.data));
          commit(types.SET_ENUMS_CARBONE, JSON.parse(res.data.version.enum));
          commit(types.SET_COMPLEMENT_CARBONE, JSON.parse(res.data.version.complement));
          commit(types.SET_TRANSLATIONS, JSON.parse(res.data.version.translations));
          resolve(res.message);
        } else {
          reject(res.message);
        }
      });
    });
  },

  /**
   * Change the version in the studio
   * @param {String} idVersion : ID of the version to select in the studio
   */
  setVersionInCurrent ({ commit, dispatch }, idVersion) {
    return new Promise((resolve, reject) => {
      dispatch('getIndexByIDVersion', {
        idVersion : idVersion,
        versions  : state.current.versions
      }).then((index) => {
        commit(types.SET_VERSION, state.current.versions[index]);
        commit(types.SET_DATA_CARBONE, JSON.parse(state.current.versions[index].data));
        commit(types.SET_ENUMS_CARBONE, JSON.parse(state.current.versions[index].complement));
        commit(types.SET_COMPLEMENT_CARBONE, JSON.parse(state.current.versions[index].enum));
        commit(types.SET_TRANSLATIONS, JSON.parse(state.current.versions[index].translations));
        commit(types.SET_ID_FILE_SERVER, state.current.version.filename);
        commit(types.SET_EXT, state.current.versions[index].ext);
        commit(types.SET_CAN_SAVE, false);
        bus.$emit('reset-live-reload-download');
        bus.$emit('reset-live-reload-upload');
        resolve();
      }, () => {
        reject();
      });
    });
  },

  /**
   * Add a new version to a report
   * @param {Object} data : contain version informations
   */
  addVersion ({ commit }, data) {
    return new Promise((resolve, reject) => {
      currentTemplate.addVersion(data.idReport, data.data, (res) => {
        if (res.success === true) {
          commit(types.PUSH_VERSION, res.data.version);
          commit(types.SET_VERSION, res.data.version);
          commit(types.SET_ID_FILE_SERVER, res.data.version.filename);
          commit(types.SET_EXT, res.data.version.ext);
          commit(types.SET_SHARED, false);
          commit(types.SET_DATA_CARBONE, {});
          commit(types.SET_TRANSLATIONS, {});
          commit(types.SET_ENUMS_CARBONE, {});
          commit(types.SET_COMPLEMENT_CARBONE, {});
          return resolve(res.message);
        }

        return reject(res.message);
      });
    });
  },
  /**
   * Get version for report and set them in current
   */
  getVersions ({ commit }, idReport) {
    return new Promise((resolve, reject) => {
      currentTemplate.getVersions(idReport, (data) => {
        var versions = data.data;
        if (data.success) {
          commit(types.SET_ALL_VERSIONS, versions);

          let currentVersion = versions.find((version) => {
            return version.id === state.current.version.id;
          });

          if (versions.length > 0 && currentVersion !== undefined && currentVersion !== null) {
            commit(types.SET_VERSION, currentVersion);
            commit(types.SET_ID_FILE_SERVER, currentVersion.filename);
          }

          return resolve();
        }

        return reject(data.message);
      });
    });
  },

  /**
   * Get versions for a report and return them
   */
  getReportVersions ({ commit }, idReport) {
    return new Promise((resolve, reject) => {
      currentTemplate.getVersions(idReport, (data) => {
        var versions = data.data;
        if (data.success) {
          commit(types.SET_ALL_VERSIONS, versions);
          return resolve(versions);
        }

        return reject(data.message);
      });
    });
  },

  getReportInfo ({ commit }, idReport) {
    currentTemplate.getReportInfos(idReport, (data) => {
      var report = data.data;
      if (data.success) {
        commit(types.SET_CATEGORY, report.category);
        commit(types.SET_REPORT, report);
      }
    });
  },

  /**
   * Render a version
   * @param {Object} data : contain the version ID, data, complement, enum...
   */
  render ({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      currentTemplate.render(data.idVersion, data.data, this.getters.currentVersion, (res) => {
        if (res.success === false) {
          reject(res.message);
        } else {
          commit(types.SET_FILENAME_TO_PREVIEW, res.data.filename);
          commit(types.SET_FILE_INPUT_EXTENSION, res.data.ext);
          commit(types.SET_EXT, res.data.ext);
          dispatch('getExportFormatList', res.data.ext);
          resolve();
        }
      });
    });
  },

  /**
   * Return the content file
   * @param {Object} _
   * @param {Object} data Contain the filename to download
   */
  downloadTemplate (_, data) {
    return new Promise((resolve, reject) => {
      currentTemplate.downloadTemplate(data.filename, (err, file) => {
        if (err) {
          reject(err.toString());
        } else {
          resolve(file);
        }
      });
    });
  },

  /**
   * Get a shared version by key
   * @param {String} key : the share key
   */
  getSharedVersion ({ commit }, key) {
    return new Promise((resolve, reject) => {
      currentTemplate.getSharedVersion(key, (res) => {
        if (res.success === true) {
          commit(types.SET_REPORT, res.data.report);
          commit(types.SET_VERSION, res.data.version);
          commit(types.SET_ALL_VERSIONS, [res.data.version]);
          commit(types.SET_CATEGORY, null);
          commit(types.SET_TAGS, null);
          commit(types.SET_ID_FILE_SERVER, state.current.version.filename);
          commit(types.SET_EXT, res.data.version.ext);
          commit(types.SET_DATA_CARBONE, JSON.parse(res.data.version.data));
          commit(types.SET_ENUMS_CARBONE, JSON.parse(res.data.version.enum));
          commit(types.SET_COMPLEMENT_CARBONE, JSON.parse(res.data.version.complement));
          commit(types.SET_TRANSLATIONS, JSON.parse(res.data.version.translations));
          commit(types.SET_SHARED, true);
          resolve();
        } else {
          reject(res.message);
        }
      });
    });
  },

  /**
   * Duplicate a shared version to get it in local
   * @param {Object} data : contain idVersion and data for duplication
   */
  duplicateVersion ({ commit }, data) {
    return new Promise((resolve, reject) => {
      currentTemplate.duplicateVersion(data.idVersion, data.data, (res) => {
        if (res.success === true) {
          commit(types.SET_REPORT, res.data.report);
          commit(types.SET_VERSION, res.data.version);
          commit(types.SET_ALL_VERSIONS, [res.data.version]);
          commit(types.SET_CATEGORY, res.data.category);
          commit(types.SET_TAGS, null);
          commit(types.SET_ID_FILE_SERVER, state.current.version.filename);
          commit(types.SET_EXT, res.data.version.ext);
          commit(types.SET_DATA_CARBONE, JSON.parse(res.data.version.data));
          commit(types.SET_ENUMS_CARBONE, JSON.parse(res.data.version.enum));
          commit(types.SET_COMPLEMENT_CARBONE, JSON.parse(res.data.version.complement));
          commit(types.SET_TRANSLATIONS, JSON.parse(res.data.version.translations));
          commit(types.SET_SHARED, false);
          resolve(res.message);
        } else {
          reject(res.message);
        }
      });
    });
  },

  uploadTemplate ({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      currentTemplate.uploadTemplate(data.idVersion, data.data, (res) => {
        if (res.success) {
          if (state.idFileServer !== res.data.filename) {
            bus.$emit('template-id-updated');
          }
          commit(types.SET_ID_FILE_SERVER, res.data.filename);
          commit(types.SET_VERSION_TEMPLATE_ID, res.data.filename);
          commit(types.SET_EXT, res.data.ext);
          
          resolve();
        } else {
          reject(res.message);
        }
      });
    });
  },

  /**
   * Update a report with new informations
   * @param {Object} data : new informations for report
   */
  updateReport ({ commit }, data) {
    return new Promise((resolve, reject) => {
      currentTemplate.updateReport(data.idReport, data.data, (res) => {
        if (res.success === true) {

          // Update info if the report updated is in current
          if (res.data.id === state.current.report.id) {

            let tmpReport = JSON.parse(JSON.stringify(state.current.report));
            tmpReport.originalFileName = res.data.originalFileName;
            commit(types.SET_REPORT, tmpReport);
            commit(types.SET_CATEGORY, res.data.category);
          }
          resolve(res.message);
        } else {
          reject(res.message);
        }
      });
    });
  },
  setIdFileServer ({ commit }, id) {
    commit(types.SET_ID_FILE_SERVER, id);
  },
  setExt ({ commit }, ext) {
    commit(types.SET_EXT, ext);
  },
  setCategory ({ commit }, category) {
    commit(types.SET_CATEGORY, category);
  },
  setReport ({ commit }, report) {
    commit(types.SET_REPORT, report);
  },
  setVersion ({ commit }, version) {
    commit(types.SET_VERSION, version);
  },
  setAllVersions ({ commit }, versions) {
    commit(types.SET_ALL_VERSIONS, versions);
  },
  setCanSave ({ commit }, canSave) {
    commit(types.SET_CAN_SAVE, canSave);
  },
  updateIdOrder ({ commit }, dir) {
    commit(types.UPDATE_ID_ORDER, dir);
  },
  setFile ({ commit }, file) {
    commit(types.SET_FILE, file);
  },
  initAll ({ commit }) {
    commit(types.INIT_ALL, {});
  },
  setShared ({ commit }, value) {
    commit(types.SET_SHARED, value);
  },
  setTags ({ commit }, tags) {
    commit(types.SET_TAGS, tags);
  },
  setContinueRefresh ({ commit }, value) {
    commit(types.SET_CONTINU_REFRESH, value);
  },
  setLang ({ commit }, value) {
    if (value !== undefined && value !== null) {
      commit(types.SET_LANG, value);
    }
  },
  setTimezone ({ commit }, value) {
    if (value !== undefined && value !== null) {
      commit(types.SET_TIMEZONE, value);
    }
  },
  setCurrencySource ({commit }, value) {
    if (value !== undefined && value !== null) {
      commit(types.SET_CURRENCY_SOURCE, value);
    }
  },
  setCurrencyTarget ({commit }, value) {
    if (value !== undefined && value !== null) {
      commit(types.SET_CURRENCY_TARGET, value);
    }
  }
};

const mutations = {
  [types.SET_LANG] (state, value) {
    state.lang = value;
  },
  [types.SET_TIMEZONE] (state, value) {
    state.timezone = value;
  },
  [types.SET_CURRENCY_SOURCE] (state, value) {
    state.currencySource = value;
  },
  [types.SET_CURRENCY_TARGET] (state, value) {
    state.currencyTarget = value;
  },
  [types.UPDATE_ONE_VERSION_IN_LIST] (state, value) {
    const versionIndex = state.current.versions.findIndex((version) => {
      return version.id === value.id;
    });

    if (versionIndex === -1) {
      return;
    }

    state.current.versions[versionIndex] = value;
  },
  [types.SET_CONTINU_REFRESH] (state, value) {
    state.continueRefresh = value;
  },
  [types.SET_ID_FILE_SERVER] (state, id) {
    state.idFileServer = id;
  },
  [types.SET_EXT] (state, value) {
    state.ext = value;
  },
  [types.SET_FILENAME_TO_PREVIEW] (state, filename) {
    state.fileNameToPreview = filename;
  },
  [types.SET_FILE_INPUT_EXTENSION] (state, type) {
    state.fileExtentionInputType = type;
  },
  [types.SET_SHARE_KEY] (state, key) {
    state.current.version.shareKey = key;
  },
  [types.SET_CATEGORY] (state, category) {
    if (category === null || category.id === undefined) {
      state.current.category = null;
    } else {
      state.current.category = category;
    }
  },
  [types.SET_REPORT] (state, report) {
    state.current.report = report;
  },
  [types.SET_VERSION] (state, version) {
    state.current.version = JSON.parse(JSON.stringify(version));
  },
  [types.SET_VERSION_TEMPLATE_ID] (state, templateId) {
    if (state.current.version && state.current.version.filename) {
      const _previousTemplateId = state.current.version.filename;
      /** UPdate the current opened version */
      state.current.version.filename = templateId;
      /** Update the list of version */
      const _version = state.current.versions.find((el => el.filename === _previousTemplateId));
      _version.filename = templateId;
    }
  },
  [types.SET_ALL_VERSIONS] (state, versions) {
    state.current.versions = JSON.parse(JSON.stringify(versions));
    state.current.versions = orderVersions(state.current.versions);
  },
  [types.PUSH_VERSION] (state, version) {
    state.current.versions.unshift(JSON.parse(JSON.stringify(version)));
    state.current.versions = orderVersions(state.current.versions);
  },
  [types.SET_CAN_SAVE] (state, canSave) {
    state.current.canSave = canSave;
  },
  [types.SET_TAGS] (state, tags) {
    state.current.tags = tags;
  },
  [types.SET_FILE] (state, file) {
    state.current.file = file;
  },
  [types.INIT_ALL] (state) {
    state.current = {
      report      : {},
      version     : {},
      versions    : [],
      category    : {},
      tags        : [],
      file        : null,
      canSave     : false,
      fileContent : null,
      shared      : false
    };
    state.old = {
      report  : {},
      version : {}
    };
    state.idFileServer = '';
    state.ext = '';
  },
  [types.SET_SHARED] (state, value) {
    state.current.shared = value;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};

/**
 * Order an array of object with a versionNumber key
 * @param {Array} versions [{
 *   versionNumber : "0.0.0"
 * }]
 */
function orderVersions (versions) {
  return versions.sort((versionA, versionB) => {
    let versionASplitted = versionA.versionNumber.split('.');
    let versionBSplitted = versionB.versionNumber.split('.');

    const k = Math.min(versionASplitted.length, versionBSplitted.length);

    for (let i = 0; i < k; i++) {
      versionASplitted[i] = parseInt(versionASplitted[i], 10);
      versionBSplitted[i] = parseInt(versionBSplitted[i], 10);
      if (versionASplitted[i] > versionBSplitted[i]) {
        return -1;
      }
      if (versionASplitted[i] < versionBSplitted[i]) {
        return 1;
      }
    }
    return versionASplitted.length === versionBSplitted.length ? 0 : (versionASplitted.length < versionBSplitted.length ? 1 : -1);
  });
}
