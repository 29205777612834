<template>
  <md-sidenav :class="{ 'md-fixed' : isFixed }" class="md-right" :ref="getRef" @open="opened" @close="closed">
    <sidenav-menu @clickItem="clickItem()"></sidenav-menu>
  </md-sidenav>
</template>

<script>
import SidenavMenu from './menu/SidenavMenu.vue';
import bus from 'src/Components/bus';
import { mapGetters } from 'vuex';

export default {
  components : {
    SidenavMenu
  },
  props : {
    isFixed : {
      type    : Boolean,
      default : true
    }
  },
  data () {
    return {
    };
  },
  computed : {
    ...mapGetters({
      email : 'email'
    }),
    getRef : () => 'dashboardSidenav',
    wwidth () {
      return window.innerWidth;
    }
  },
  methods : {
    clickItem () {
      if (this.wwidth <= 1280) {
        this.close();
      }
    },
    opened () {
      if (this.wwidth > 1280) {
        this.$emit('shiftWrapper');
      }
    },
    closed () {
      this.$emit('unshiftWrapper');
    },
    open () {
      this.$refs[this.getRef].open();
    },
    close () {
      this.$refs[this.getRef].close();
    },
    toggle () {
      this.$refs[this.getRef].toggle();
    },
    dashboardSidenav (action) {
      switch (action) {
        case 'open':
          this.open();
          break;
        case 'close':
          this.close();
          break;
        case 'toggle':
          this.toggle();
          break;
        default:
          this.toggle();
          break;
      }
    }
  },
  mounted () {
    bus.$on('dashboardSidenav', (action) => {
      this.dashboardSidenav(action);
    });
  }
};
</script>

<style lang="scss">
.static-sidenav {
  .md-sidenav-content {
    box-shadow: none !important;
    z-index: 1;
    padding: 1em;
    background: rgba(0, 0, 0, .025) !important;
  }
  .md-backdrop {
    display: none !important;
  }
}
</style>
