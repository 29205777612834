<template id="editor">
  <wrapper ref="fullHeightWrapper">
      <md-layout class="md-whiteframe" md-gutter>
          <md-menu md-align-trigger style="position:absolute;top:-44px;left:2px;">
            <md-button class="md-raised" style="min-width: 195px;border-radius: 12px 12px 0px 0px;border:2px solid rgb(202, 202, 202);border-bottom:none;box-shadow:none;" md-menu-trigger> 
              <b v-if="editormode === 1">JSON Data-set </b> 
              <b v-if="editormode === 2">Complement</b> 
              <b v-if="editormode === 3">Translations</b> 
              <b v-if="editormode === 4">Enum</b> 
              <md-icon style="margin-top:-2px">expand_more</md-icon>
            </md-button>
            <md-menu-content>
              <md-menu-item @click.stop="editormode = 1">JSON Data-set {d.}</md-menu-item>
              <md-menu-item @click.stop="editormode = 2">Complement Data {c.}</md-menu-item>
              <md-menu-item @click.stop="editormode = 3">Translations {t()}</md-menu-item>
              <md-menu-item @click.stop="editormode = 4">Enum</md-menu-item>
            </md-menu-content>
          </md-menu>
          <span style="position: absolute;top: -34px;left: 210px;">
            <md-button :disabled="current.canSave === false" @click.stop="saveVersionData" class="md-dense md-icon-button"><md-icon>save</md-icon><md-tooltip md-direction="bottom"><b>Save JSON Data / Complement / Translations</b></md-tooltip></md-button><md-button  @click.stop="repairJSON" class="md-dense md-icon-button"><md-icon>build_circle</md-icon><md-tooltip md-direction="bottom"><b>Repair JSON</b></md-tooltip></md-button>
          </span>
          <span style="position: absolute;top: -44px;right:calc(50% - 224px)">
            <md-menu md-align-trigger>
              <md-button :disabled="versionLoaded === false"  md-menu-trigger class="md-raised" style="border-radius: 12px 12px 0px 0px;padding:0px 20px !important;border:2px solid rgb(202, 202, 202);border-bottom:none;box-shadow:none;">
                <b>Document Preview</b> 
                <md-icon style="font-size:22px; margin-top:-2px">download</md-icon>
                <md-tooltip md-direction="bottom"><b>Save As</b></md-tooltip>
              </md-button>
              
              <md-menu-content style="max-height:500px">
                <md-menu-item @click.stop="saveDocumentAs(formats.extension)" v-for="formats in exportFormatList" :key="formats.extension">{{ formats.description }} (.{{ formats.extension }})</md-menu-item>
              </md-menu-content>
            </md-menu>
          </span>
          <vue-menu-group style="position: absolute;top: -35px;right:calc(50% - 273px)">
            <vue-menu>
              <vue-menu-trigger class="trigger-settings">
                <md-button class="md-dense md-icon-button">
                  <md-icon>settings</md-icon>
                  <md-tooltip mdDirection="bottom">Settings</md-tooltip>
                </md-button>
              </vue-menu-trigger>

              <vue-menu-content>
                <!-- <vue-menu-item :clickable="false"><live-reload-toggler class="md-icon"></live-reload-toggler> <span>Live reloading 🚩 TODO: DELETE</span></vue-menu-item> -->
                <vue-menu-item :clickable="false">
                  <md-input-container>
                    <label for="carboneVersion">Carbone API Version</label>
                    <md-select name="carboneVersion" id="carboneVersion" v-model="currentCarboneVersion">
                      <md-option v-for="version in carboneVersions" v-bind:key="version" :value="version">{{ version }}.x.x</md-option>
                    </md-select>
                  </md-input-container>
                </vue-menu-item>
                <vue-menu-item :clickable="false">
                  <md-input-container>
                    <label for="lang">Document lang</label>
                    <md-select name="lang" id="lang" v-model="reportLang">
                      <md-option v-for="lang in langs" :value="lang.code" :key="lang.code">{{ lang.name }} ({{ lang.code }})</md-option>
                    </md-select>
                  </md-input-container>
                </vue-menu-item>
                <vue-menu-item :clickable="false">
                  <md-input-container>
                    <label for="timezone">Document Timezone</label>
                    <md-select name="timezone" id="timezone" v-model="reportTimezone">
                      <md-option v-for="zone in timezones" :value="zone.code" :key="zone.code">{{zone.code}}</md-option>
                    </md-select>
                  </md-input-container>
                </vue-menu-item>
                <vue-menu-item :clickable="false">
                  <md-input-container>
                    <label for="currencySource">Currency Source</label>
                    <md-select name="currencySource" id="currencySource" v-model="currencyS">
                      <md-option v-for="cur in currencies" :value="cur.code" :key="cur.code">{{ cur.name }} ({{ cur.code }})</md-option>
                    </md-select>
                  </md-input-container>
                </vue-menu-item>
                <vue-menu-item :clickable="false">
                  <md-input-container>
                    <label for="currencyTarget">Currency Target</label>
                    <md-select name="currencyTarget" id="currencyTarget" v-model="currencyT">
                      <md-option v-for="cur in currencies" :value="cur.code" :key="cur.code">{{ cur.name }} ({{ cur.code }})</md-option>
                    </md-select>
                  </md-input-container>
                </vue-menu-item>
              </vue-menu-content>
            </vue-menu>
          </vue-menu-group>

          <md-button v-if="liveReloadNotActive" @click="openArticleLiveReload" style="position: absolute;top: -38px;right: calc(50% - 530px);" class="md-dense md-warn"><md-icon style="font-size:20px;margin-right: 5px;">sync</md-icon><b>Live-reload not available</b></md-button>

          <md-layout md-column md-gutter style="border-top: 2px solid rgb(202, 202, 202);border-right: 2px solid rgb(202, 202, 202);padding-right:1px;">
            <jsoneditor ref="jsoneditor" :jsoneditormode="editormode"></jsoneditor>
          </md-layout>
          <md-layout md-column md-gutter style="border-top: 2px solid rgb(202, 202, 202)">
              <pdfviewer></pdfviewer>
          </md-layout>
      </md-layout>
  </wrapper>
  
</template>

<style>
  .trigger-settings {
    padding: 0px !important;
    margin: 0px !important;
  }
 .trigger-settings:hover {
    background: none !important;
    border-radius: none!important;
    color:none!important; 
 } 
</style>


<script>
import jsoneditor from './JSONEditor.vue';
import pdfviewer  from './PdfViewer.vue';
import wrapper    from './FullHeightWrapper.vue';
import bus        from 'src/Components/bus';
import { mapGetters, mapActions } from 'vuex';

import currencies     from '../api/currencies';
import langs          from '../api/langs';
import timezones      from '../api/timezones';

import LiveReloadToggler  from './toolbar/secondary/LiveReloadToggler.vue';

export default {
  components : {
    wrapper,
    jsoneditor,
    pdfviewer,
    LiveReloadToggler
  },
  data : function () {
    return {
      editormode            : 1,
      currencies            : currencies,
      langs                 : langs,
      timezones             : timezones,
      currencyS             : null,
      currencyT             : null,
      currentCarboneVersion : null,
      reportLang            : null,
      reportTimezone        : null
    };
  },
  computed : {
    ...mapGetters({
      carboneVersions      : 'carboneVersions',
      currentVersion       : 'currentVersion',
      lang                 : 'lang',
      timezone             : 'timezone',
      currencySource       : 'currencySource',
      currencyTarget       : 'currencyTarget',
      exportFormatList     : 'exportFormatList',
      dataForCarbone       : 'dataForCarbone',
      complementForCarbone : 'complementForCarbone',
      enumsForCarbone      : 'enumsForCarbone',
      translations         : 'translations',
      current              : 'current',
      fileNameToPreview    : 'fileNameToPreview',
      idFileServer         : 'idFileServer'
    }),
    versionLoaded () {
      if (this.current.report.id) {
        return true;
      }
      return false;
    },
    liveReloadNotActive () {
      return 'showOpenFilePicker' in window === false;
    }
  },
  watch : {
    currentCarboneVersion : function (newValue) {
      if (newValue && newValue !== this.currentVersion) {
        window.localStorage.setItem('carboneVersion', newValue);
        this.$store.dispatch('setCarboneVersion', newValue);
        bus.$emit('refreshPreview', 'currentCarboneVersion');
      }
    },
    reportLang (newValue) {
      if (newValue && newValue !== this.lang) {
        window.localStorage.setItem('reportLang', newValue);
        this.setLang(newValue);
        bus.$emit('refreshPreview', 'reportLang');
      }
    },
    reportTimezone (newValue) {
      if (newValue && newValue !== this.timezone) {
        window.localStorage.setItem('reportTimezone', newValue);
        this.setTimezone(newValue);
        bus.$emit('refreshPreview', 'reportTimezone');
      }
    },
    currencyS (newValue) {
      if (newValue && newValue !== this.currencySource) {
        window.localStorage.setItem('currencySource', newValue);
        this.setCurrencySource(newValue);
        bus.$emit('refreshPreview', 'currencyS');
      }
    },
    currencyT (newValue) {
      if (newValue && newValue !== this.currencyTarget) {
        window.localStorage.setItem('currencyTarget', newValue);
        this.setCurrencyTarget(newValue);
        bus.$emit('refreshPreview', 'currencyT');
      }
    }
  },
  methods : {
    ...mapActions({
      setLang           : 'setLang',
      setTimezone       : 'setTimezone',
      setCurrencySource : 'setCurrencySource',
      setCurrencyTarget : 'setCurrencyTarget',
      render            : 'render',
      saveVersion       : 'saveVersion',
      setCanSave        : 'setCanSave',
      toastError        : 'toastError',
      toastSuccess      : 'toastSuccess'      
    }),
    shift (action) {
      const wrapper = this.$refs.fullHeightWrapper;
      if (action === 'toggle') {
        wrapper.toggle();
      } 
      if (action === 'shift') {
        wrapper.shift();
      }
      if (action === 'unshift') {
        wrapper.unshift();
      }
    },
    repairJSON () {
      this.$refs.jsoneditor.$refs.jsoneditor.querySelector('.jsoneditor-repair').click();
      this.$refs.jsoneditor.$refs.jsoneditor.querySelector('.jsoneditor-format').click();
    },
    saveVersionData : function () {
      if (typeof this.current.report.id === 'undefined' || typeof this.current.version === 'undefined') {
        return;
      }

      // Set current data in vuex
      bus.$emit('updateDataJson');

      var data = {
        dataSample       : this.dataForCarbone,
        enumSample       : this.enumsForCarbone,
        complementSample : this.complementForCarbone,
        filename         : this.idFileServer,
        translations     : this.translations
      };

      this.setCanSave(false);
      // Call the request
      this.saveVersion({
        id   : this.current.version.id,
        data : data
      }).then(() => {
        this.toastSuccess('JSON Data Saved!');
        
      }, (msg) => {
        console.log('Error: ', msg);
      });
    },
    saveDocumentAs : function (convertTo) {
      bus.$emit('log', {
        message : 'Exporting in .' + convertTo,
        type    : 'info'
      });

      // Prepare data
      var data = {
        data           : JSON.stringify(this.dataForCarbone),
        complement     : JSON.stringify(this.complementForCarbone),
        enum           : JSON.stringify(this.enumsForCarbone),
        translations   : JSON.stringify(this.translations),
        currencySource : this.currencySource,
        currencyTarget : this.currencyTarget,
        convertTo      : convertTo,
        lang           : this.lang,
        timezone       : this.timezone
      };

      // IMPORTANT: the template must be the last thing added to the form, otherwise multer hangs on server side. https://github.com/expressjs/multer/issues/53
      // Call the request
      this.render({
        idVersion : this.current.version.id,
        data      : data
      }).then(() => {

        // Success, we can download the result now
        var filePath = '/report/' + this.fileNameToPreview + '/download';
        var a = document.createElement('A');
        a.href = filePath;
        a.download = filePath.substr(filePath.lastIndexOf('/') + 1);
        document.body.appendChild(a);
        a.click();

        // Put a log to inform the user
        bus.$emit('log', {
          message : 'Document saved: ' + this.fileNameToPreview,
          type    : 'info'
        });
        document.body.removeChild(a);
      }, (filename) => {

        // Error, display the correct log
        let message = 'Document processing failed: ';

        if (filename !== null) {
          message += filename;
        }
        return bus.$emit('log', {
          message : message,
          type    : 'error'
        });
      });
    },
    copyTemplateId () {
      try {
        navigator.clipboard.writeText(this.idFileServer).then(() => {
          // copy success
          this.toastSuccess('Template ID copied');
        }, function (err) {
          this.toastError('Copying template ID failled, contact the support.');
          console.error('Async: Could not copy text: ', err);
        });
      } catch (err) {
        this.toastError('Copying template ID failled, contact the support.');
        console.error('Copy error:' + err.toString());
      }
    },
    openArticleLiveReload () {
      const id = "how-to-enable-live-reload-1pd53ln"
      try {
        if (window.$crisp) {
          window.$crisp.push(["do", "helpdesk:article:open", ['en-us', id]])
        } else {
          window.open(`https://help.carbone.io/en-us/article/${id}`, "_blank");
        }
      } catch(err) {
        console.log("Helpdesk article not available: ", err.toString());
      }
    }
  },
  mounted () {
    bus.$on('shiftFHWrapper', (action) => {
      this.shift(action);
    });
    bus.$on('saveVersion', () => {
      this.saveVersionData();
    });
    bus.$on('copyTemplateId', () => {
      this.copyTemplateId();
    });
  },
  created () {
    this.$store.dispatch('getCarboneVersions').then(() => {
      const carboneVersion = window.localStorage.getItem('carboneVersion');
      if (!this.currentCarboneVersion && carboneVersion) {
        this.currentCarboneVersion = carboneVersion;
        this.$store.dispatch('setCarboneVersion', carboneVersion);
      } else if (!this.currentCarboneVersion) {
        this.$store.dispatch('setCarboneVersion', this.carboneVersions[this.carboneVersions.length - 1]);
        this.currentCarboneVersion = this.currentVersion;
        window.localStorage.setItem('carboneVersion', this.currentVersion);
      }
    });
  
    const localStorageLang = window.localStorage.getItem('reportLang') || 'en-gb';
    if (!this.reportLang && localStorageLang) {
      this.setLang(localStorageLang);
      this.reportLang = this.lang;
    }
    
    const localStorageTimezone = window.localStorage.getItem('reportTimezone') || 'europe/london';
    if (!this.reportTimezone && localStorageTimezone) {
      this.setTimezone(localStorageTimezone);
      this.reportTimezone = this.timezone;
    }

    const localStorageCurrencySource = window.localStorage.getItem('currencySource') || 'EUR';
    if (!this.currencyS && localStorageCurrencySource) {
      this.setCurrencySource(localStorageCurrencySource);
      this.currencyS = this.currencySource;
    }

    const localStorageCurrencyTarget = window.localStorage.getItem('currencyTarget') || 'EUR';
    if (!this.currencyT && localStorageCurrencyTarget) {
      this.setCurrencyTarget(localStorageCurrencyTarget);
      this.currencyT = this.currencyTarget;
    }
  }
};
</script>
