<template>
  <div style="height: 100%;">
    <div id="pdfviewer" style="height: 100%;padding-top: 0;">
      <object :type="isSafari ? false: 'application/pdf'" id="pdfviewerobject" class="pdfviewer-size" style="height: 100%; width: 100%"></object>
    </div>
  </div>
</template>

<script>
import bus            from './bus';
import { mapGetters } from 'vuex';

export default {
  data () {
    return {
      isSafari : /apple/i.test(navigator.vendor)
    };
  },
  components : {
  },
  computed : {
    ...mapGetters({
      current           : 'current',
      fileNameToPreview : 'fileNameToPreview'
    })
  },
  methods : {
    refreshPreview : function () {
      bus.$emit('refreshPreview', this.current.report, this.current.version);
    },
    displayPreview : function () {
      var pdfviewer = document.getElementById('pdfviewerobject');

      if (pdfviewer !== null) {
        pdfviewer.data = '/previews/' + this.fileNameToPreview + '/display';
      }
    },
    resetPreview : function () {
      var pdfviewer = document.getElementById('pdfviewerobject');
      pdfviewer.data = '';
    }
  },
  mounted () {
    bus.$on('refreshCurrentPreview', () => {
      this.refreshPreview();
    });
    bus.$on('displayPreview', () => {
      this.displayPreview();
    });
    bus.$on('resetPreview', () => {
      this.resetPreview();
    });
  }
};
</script>