// This file is for Hermes
export default {
  carboneVersions : {
    getList : {
      route  : '/carbone/versions',
      method : 'GET'
    }
  },
  log : {
    getOne : {
      route  : '/log/{reportId}/{versionId}/{logId}',
      method : 'GET'
    },
    getVersionLogs : {
      route  : '/log/details/version/{idVersion}',
      method : 'GET'
    }
  },
  documentation : {
    route  : '/documentation',
    method : 'GET'
  },
  mail : {
    info : {
      route : '/mail/info',
      method : 'GET'
    },
    update : {
      route : '/mail/update',
      method : 'POST'
    },
    test : {
      route : '/mail/test',
      method : 'POST'
    }
  },
  account : {
    generateKeyPair : {
      route  : '/generate-public-key',
      method : 'GET'
    },
    getTva : {
      route   : '/account/tva',
      method  : 'GET'
    },
    accountInformations : {
      route  : '/account/informations',
      method : 'GET'
    },
    getBills : {
      route  : '/account/bills',
      method : 'GET'
    },
    genBill : {
      route   : '/account/bill/generate/{id}',
      method  : 'GET'
    },
    getCard : {
      route  : '/account/card',
      method : 'GET',
    },
    stats : {
      route  : '/account/stats/{from}/{to}',
      method : 'GET'
    },
    rendersPerDay : {
      route  : '/log/details/day',
      method : 'GET'
    },
    updateCard : {
      route  : '/account/update/card',
      method : 'POST'
    },
    addCard : {
      route  : '/account/add/card',
      method : 'POST'
    },
    deleteCard : {
      route  : '/account/delete/card',
      method : 'POST'
    },
    subscribeOffer : {
      route  : '/account/subscribe/{idOffer}',
      method : 'POST'
    },
    updateAdvancedInfo : {
      route  : '/account/update/advanced',
      method : 'POST'
    },
    updateEnterpriseInfo : {
      route  : '/account/update/enterprise',
      method : 'POST'
    },
    rechargeAccount : {
      route : '/account/recharge',
      method : 'POST'
    },
    automaticRecharge : {
      route : '/account/recharge/automatic',
      method : 'POST'
    },
    deactivateRecharge : {
      route : '/account/recharge/automatic/deactivate',
      method : 'GET'
    }
  },
  offers : {
    all : {
      route : '/account/offers',
      method : 'GET'
    }
  },
  category : {
    categories : {
      route  : '/categories',
      method : 'GET'
    },
    category : {
      route  : '/category/{id}',
      method : 'GET'
    },
    addCategory : {
      route  : '/category/add',
      method : 'POST'
    },
    deleteCategory : {
      route  : '/category/{id}/delete',
      method : 'DELETE'
    },
    updateCategory : {
      route  : '/category/{id}/update',
      method : 'POST'
    }
  },
  country : {
    country : {
      route  : '/country',
      method : 'GET'
    }
  },
  key : {
    getKey : {
      route  : '/key',
      method : 'POST'
    },
    generateKey : {
      route  : '/key/generate',
      method : 'POST'
    },
    getKeys : {
      route  : '/keys',
      method : 'GET'
    },
    deleteKey : {
      route  : '/key/{key}/delete',
      method : 'DELETE'
    }
  },
  login : {
    login : {
      route  : '/login',
      method : 'POST'
    },
    logout : {
      route  : '/logout',
      method : 'POST'
    },
    validLogin : {
      route  : '/account/login/validate',
      method : 'POST'
    }
  },
  register : {
    register : {
      route  : '/register',
      method : 'POST'
    }
  },
  report : {
    getOneReport : {
      route  : '/report/{id}',
      method : 'GET'
    },
    getReports : {
      route  : '/reports',
      method : 'GET'
    },
    getReportsByCateg : {
      route  : '/reports/by-categ',
      method : 'GET'
    },
    getReportsWithoutCategory : {
      route  : '/reports/without-categ',
      method : 'GET'
    },
    getReportTags : {
      route  : '/report/{id}/tags',
      method : 'GET'
    },
    addReport : {
      route  : '/report/add',
      method : 'POST'
    },
    updateReport : {
      route  : '/report/{id}/update',
      method : 'POST'
    },
    deleteReport : {
      route  : '/report/{id}/delete',
      method : 'DELETE'
    },
    addVersion : {
      route  : '/report/{id}/version/add',
      method : 'POST'
    },
    getVersions : {
      route  : '/report/{id}/version',
      method : 'GET'
    },
    getExtension : {
      route  : '/report/export/extension',
      method : 'GET'
    },
    previewReport : {
      route  : '/previews/{filename}/display',
      method : 'GET'
    },
    getSampleData : {
      route  : '/sample_data',
      method : 'GET'
    },
    render : {
      route  : '/version/{id}/render',
      method : 'POST'
    },
    downloadTemplate : {
      route  : '/templates/{filename}/download',
      method : 'GET'
    },
    getFullReport : {
      route : '/report/full/{id}',
      method: 'GET'
    }
  },
  tag : {
    getTags : {
      route  : '/tags',
      method : 'GET'
    },
    getOneTag : {
      method : 'GET',
      route  : '/tag/{id}'
    },
    addTag : {
      route  : '/tag/add',
      method : 'POST'
    },
    updateTag : {
      route  : '/tag/{id}/update',
      method : 'POST'
    },
    deleteTag : {
      route  : '/tag/{id}/delete',
      method : 'DELETE'
    }
  },
  user : {
    userInfo : {
      route  : '/user/informations',
      method : 'GET'
    },
    activateUser : {
      route  : '/user/activate/{id}',
      method : 'GET'
    },
    deactivateUser : {
      route  : '/user/deactivate/{id}',
      method : 'GET'
    },
    resetPassword : {
      route  : '/user/resetpassword',
      method : 'POST'
    },
    hardReset : {
      route : '/user/hardreset/{id}',
      method: 'POST'
    },
    updatePassword : {
      route  : '/user/updatepassword',
      method : 'POST'
    },
    addUser : {
      route  : '/user/adduser',
      method : 'PUT'
    },
    updateUser : {
      route : '/user/update/{id}',
      method: 'POST'
    },
    updateMyInfo : {
      route : '/user/update',
      method: 'POST'
    },
    getUsers : {
      route  : '/user/getusers',
      method : 'GET'
    },
    getUser : {
      route  : '/user/getuser/{id}',
      method : 'GET'
    },
    delete : {
      route  : '/user/delete/{id}',
      method : 'DELETE'
    },
    hasKey : {
      route  : '/user/haskey',
      method : 'GET'
    },
    getSubscriptionValue : {
      route  : '/plans/value/{subscriptionName}/{keyValue}',
      method : 'GET'
    }
  },
  version : {
    sample : {
      route  : '/version/sample/template',
      method : 'GET'
    },
    uploadTemplate : {
      route  : '/version/{idVersion}/update/template',
      method : 'POST'
    },
    getStatsVersion : {
      route  : '/version/{idVersion}/stats',
      method : 'GET'
    },
    getOneVersion : {
      route  : '/version/{id}',
      method : 'GET'
    },
    getVersionByKey : {
      route  : '/version/key/{key}',
      method : 'GET'
    },
    getDataVersion : {
      route  : '/version/{id}/data',
      method : 'GET'
    },
    saveVersion : {
      route  : '/version/{id}/save',
      method : 'POST'
    },
    updateVersion : {
      route  : '/version/{id}/update',
      method : 'POST'
    },
    saveAsVersion : {
      route  : '/version/{id}/saveas',
      method : 'POST'
    },
    deleteVersion : {
      route  : '/version/{id}/delete',
      method : 'DELETE'
    },
    duplicateVersion : {
      route  : '/version/{id}/duplicate',
      method : 'POST'
    }
  },
  offer : {
    chooseOffer : {
      route  : '/offer/{id}/choose',
      method : 'POST'
    },
    getOffers : {
      route  : '/offers',
      method : 'GET'
    }
  },
  epayment : {
    create : {
      route  : '/e-payment',
      method : 'POST'
    },
    getStatus : {
      route  : '/e-payment/{id}',
      method : 'GET'
    }
  }
};
