<template>
  <div>
    <md-dialog ref="saveAsDialog" @open="resetForm">
      <md-dialog-title>Create a new version<br><span style="font-size: 18px;color: gray;">from the current template</span></md-dialog-title>

      <form id="formSaveAs" v-if="typeof current.report.id != 'undefined'" v-on:submit.prevent="checkForm()">
        <md-dialog-content style="padding-top:0">
          
          <md-input-container>
            <label>Version Tag X.X.X</label>
            <md-input id="newVersionNumber" v-model="newVersion.number" required></md-input>
          </md-input-container>

          <md-input-container>
            <label>Description (optional)</label>
            <md-input id="newVersionDescription" v-model="newVersion.description"></md-input>
          </md-input-container>

          <!-- <md-input-container>
            <label>Document name (optional)</label>
            <md-input id="newVersionReportName" v-model="newVersion.reportName"></md-input>
          </md-input-container> -->
        </md-dialog-content>

        <md-dialog-actions>
          <md-button @click="closeDialog()">cancel</md-button>
          <md-button type="submit" class="md-primary">Save new version</md-button>
        </md-dialog-actions>
      </form>
      <md-dialog-content v-else>
        You can't save this template... Is it yours ?
      </md-dialog-content>
    </md-dialog>
  </div>
</template>

<script>

import bus                          from 'src/Components/bus';
import { mapGetters, mapActions }   from 'vuex';

export default {
  data () {
    return {
      newVersion : {
        number      : null,
        reportName  : '',
        description : null
      }
    };
  },
  computed : mapGetters({
    dataForCarbone       : 'dataForCarbone',
    complementForCarbone : 'complementForCarbone',
    enumsForCarbone      : 'enumsForCarbone',
    translations         : 'translations',
    current              : 'current',
  }),
  methods : {
    ...mapActions({
      saveAsVersion : 'saveAsVersion',
      toastInfo     : 'toastInfo',
      toastSuccess  : 'toastSuccess',
      toastError    : 'toastError'
    }),

    /**
     * Reset the form for at opening
     * and set the focus
     */
    resetForm () {
    },

    /**
     * Close this dialog
     */
    closeDialog () {
      if (this.$refs.saveAsDialog) {
        this.$refs.saveAsDialog.close();
      }
    },

    /**
     * Check if the version number is correct
     */
    isVersionNumberCorrect : function (number) {
      var ex = number.split('.');
      if (typeof ex[0] === 'undefined' || typeof ex[1] === 'undefined' || typeof ex[2] === 'undefined'){
        return false;
      }
      if (/^\d+$/.test(ex[0]) === false || /^\d+$/.test(ex[1]) === false || /^\d+$/.test(ex[2]) === false){
        return false;
      }
      return true;
    },

    /**
     * Check if data are correct
     * display a toast or call the request
     */
    checkForm () {
      var numberVersion = document.getElementById('newVersionNumber').value;
      if (numberVersion.trim() === '')
        return this.toastError("You must set a number's version");
      if (this.isVersionNumberCorrect(numberVersion) === false)
        return this.toastError('Error with version number (x.x.x)');
      this.submitSaveAs();
    },

    /**
     * Call the request to save a new version
     */
    submitSaveAs () {
      // Prepare data
      var desc = (this.newVersion.description === null) ? '' : this.newVersion.description;
      var data = {
        description      : desc,
        name             : this.current.report.originalFileName,
        reportNameEnd    : this.newVersion.reportName,
        numberVersion    : this.newVersion.number,
        filename         : this.current.version.filename,
        dataSample       : this.dataForCarbone,
        complementSample : this.complementForCarbone,
        enumSample       : this.enumsForCarbone,
        translations     : this.translations
      };

      this.saveAsVersion({
        idVersion : this.current.version.id,
        data      : data
      }).then((msg) => {
        this.toastSuccess(msg);
        bus.$emit('reset-live-reload-download');
        bus.$emit('reset-live-reload-upload');
        bus.$emit('refreshPreview');
        this.closeDialog();
      }, (msg) => {
        this.toastError(msg);
      });
    }
  },
  mounted () {
    bus.$on('newVersionFromCurrentVersion', () => {
      if (this.$refs.saveAsDialog) {
        this.$refs.saveAsDialog.open();
      }
    });
  },
  onUnmounted () {
    bus.$off('newVersionFromCurrentVersion');
  }
};
</script>