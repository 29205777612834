<template>
  <md-dialog ref="ModalEditVersion">
    <md-dialog-title>Edit a version</md-dialog-title>

    <form @submit.prevent="submit">
      <md-dialog-content>
        <md-input-container>
          <label>Version Tag X.X.X</label>
          <md-input v-model="version.versionNumber" required></md-input>
        </md-input-container>

        <md-input-container>
          <label>Description (optional)</label>
          <md-input v-model="version.description"></md-input>
        </md-input-container>

        <!-- <md-input-container>
          <label>Generated filename</label>
          <md-input v-model="version.reportName"></md-input>
        </md-input-container> -->
      </md-dialog-content>

      <md-dialog-actions>
        <md-button class="md-primary" @click="close">Cancel</md-button>
        <md-button class="md-primary" @click="close" type="submit">Ok</md-button>
      </md-dialog-actions>
    </form>
  </md-dialog>
</template>

<script>

import bus              from 'src/Components/bus';
import { mapActions }   from 'vuex';

export default {
  props : {
    version : {
      type     : Object,
      required : true
    },
    report : {
      type     : Object,
      required : true
    }
  },
  computed : {
    
  },
  data () {
    return {
      modal : null
    };
  },
  methods : {
    ...mapActions({
      updateVersion : 'updateVersion',
      toastInfo     : 'toastInfo',
      toastSuccess  : 'toastSuccess',
      toastError    : 'toastError'
    }),

    /**
     * Submit the form and update informations
     * Toast the result
     */
    submit () {
      var data = {
        versionNumber : this.version.versionNumber,
        description   : this.version.description,
        reportName    : this.version.reportName
      };

      this.updateVersion({
        id   : this.version.id,
        data : data
      }).then((msg) => {
        this.toastSuccess(msg);
      }, (msg) => {
        this.toastError(msg);
      });
    },

    /**
     * Open the dialog
     */
    open () {
      if (this.$refs.ModalEditVersion) {
        this.$refs.ModalEditVersion.open();
      }
    },

    /**
     * Close the dialog
     */
    close () {
      if (this.$refs.ModalEditVersion) {
        this.$refs.ModalEditVersion.close();
      }
    }
  },
  mounted () {
    bus.$on('ModalEditVersion', () => {
      this.open();
    });
  }
};
</script>