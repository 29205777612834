<template>
  <div id="newStudio">
    <toolbar></toolbar>
    <modal-tutorial></modal-tutorial>
    <sidenav-templates></sidenav-templates>
    <sidenav-versions></sidenav-versions>

    <span v-if="versionLoaded !== true" style="display:flex;flex-direction: row;align-items: center;justify-content: center;margin-top:25px">
      <span style="display:flex;flex-direction: column;">
        <md-button @click="openNewTemplateModal" class="md-primary md-raised" style="border-radius:15px"><md-icon style="font-size: 21px;">add</md-icon> New template </md-button>
        <md-button @click="openSidenavTemplates" class="md-raised" style="border-radius:15px"><md-icon style="font-size: 20px;">lists</md-icon> List my templates </md-button>
        <md-button @click="openStudioTour"  class="md-raised"  style="border-radius:15px"><md-icon style="font-size: 20px;">play_arrow</md-icon> Studio Tutorials</md-button>
      </span>
    </span>

    <editor v-show="versionLoaded === true"></editor>
    <logs-bar v-show="versionLoaded === true"></logs-bar>

    <info-version></info-version>
    <modal-edit-report></modal-edit-report>
    <modal-add-report></modal-add-report>
    <modal-edit-version :report="current.report" :version="current.version"></modal-edit-version>
    <modal-save-as></modal-save-as>
    <modal-add-version :report="current.report"></modal-add-version>
    <modal-upload-template></modal-upload-template>
    <duplicate-modal></duplicate-modal>
  </div>
</template>

<script>
import sidenavTemplates             from './Sidenav-templates.vue';
import sidenavVersions              from './Sidenav-versions.vue';
import toolbar                      from 'src/Components/toolbar/Toolbar.vue';
import editor                       from './Editor.vue';
import modalEditReport              from './ModalEditReport.vue';
import modalAddReport               from './ModalAddReport.vue';
import duplicateModal               from './duplicate/modals/DuplicateModal.vue';
import ModalEditVersion             from 'src/Components/report/version/ModalEditVersion.vue';
import ModalAddVersion              from 'src/Components/report/version/ModalAddVersion.vue';
import ModalTutorial                from './ModalTutorial.vue';
import infoVersion                  from 'src/Components/report/version/VersionInfo.vue';
import ModalUploadTemplate          from 'src/Components/report/template/ModalUploadTemplate.vue';
import CatManager                   from 'src/Components/categories/modals/CatManager.vue';
import TagManager                   from 'src/Components/tags/modals/TagsManager.vue';
import ModalSaveAs                  from './report/version/ModalSaveAs.vue';
import LogsBar                      from './LogsBar.vue';
import { mapGetters, mapActions }   from 'vuex';
import bus                          from './bus';
import Hermes                       from 'plugins/Hermes';

export default {
  data () {
    return {
      fileChange : null
    };
  },
  components : {
    sidenavTemplates,
    sidenavVersions,
    toolbar,
    modalEditReport,
    modalAddReport,
    LogsBar,
    editor,
    infoVersion,
    ModalEditVersion,
    ModalAddVersion,
    ModalUploadTemplate,
    CatManager,
    duplicateModal,
    TagManager,
    ModalSaveAs,
    ModalTutorial
  },
  computed : {
    ...mapGetters({
      current          : 'current',
      lastModifiedFile : 'lastModifiedFile',
      support          : 'support',
      email            : 'email'
    }),
    versionLoaded () {
      if (this.current.report.id) {
        return true;
      }
      return false;
    }
  },
  watch : {
    fileChange : function (val) {
      var name = val.split('.');
      if (name[0] !== undefined) {
        bus.$emit('fileChange', val, name[0]);
      }
    }
  },
  methods : {
    ...mapActions({
      getSample           : 'getSample',
      setLastModifiedFile : 'setLastModifiedFile',
      getSharedVersion    : 'getSharedVersion',
      toastInfo           : 'toastInfo',
      toastSuccess        : 'toastSuccess',
      toastError          : 'toastError'
    }),

    /**
     * Check if the current document is shared or if there is one in current
     * If not check if there is sample and display it
     */
    checkThingToRender : function () {
      if (this.current.report !== undefined && this.current.report.id !== undefined) {
        bus.$emit('setJson', 'data');
        bus.$emit('refreshPreview');
      } else {
        // Begin here, get the sample and display it
        this.$store.dispatch('setShared', false);
        this.getSample().then(() => {
          bus.$emit('setJson', 'data');
          bus.$emit('refreshPreview');
        }, () => {});
      }
    },
    deleteVersion (data) {
      Hermes.run({
        route  : 'version.deleteVersion',
        params : {
          id : data.idVersion
        }
      }, (result) => {
        /** To update sidenav-versions and popup report */
        bus.$emit('fetch-all-versions', data.idReport);

        if (result.success) {
          this.toastSuccess(result.message);
          /** If the current template is deleted, the preview must be reset and refreshed */
          if (this.current.report.id === data.idReport && this.current.version.id === data.idVersion) {
            this.$store.dispatch('initAll');
            bus.$emit('refreshPreview');
            bus.$emit('resetPreview');
            bus.$emit('resetData');
            bus.$emit('menu-versions', 'close');
          }
        } else {
          this.toastError(result.message);
        }
      });
    },
    openStudioTour () {
      bus.$emit('tutorial');
    },
    openNewTemplateModal () {
      bus.$emit('displayAddReport', false);
    },
    openSidenavTemplates () {
      bus.$emit('menu', 'open');
    },
  },
  beforeDestroy () {
    bus.$off();
  },

  mounted () {
    this.$store.dispatch('getExportFormatList', 'odt');
    
    bus.$emit('log', {
      message : 'Welcome to Carbone Cloud Studio',
      type    : 'info'
    });
    bus.$on('deleteVersion', (data) => {
      this.deleteVersion(data);
    });
    this.checkThingToRender();
    if (window.localStorage.getItem('tutorial') !== 'closed') {
      bus.$emit('tutorial');  
    }
  }
};
</script>
