<template>
   <span v-show="active === true">
      <div class="modalBackdrop"></div>
      <div id="modal-tutorial">
         <span @click="toggle()">
            <md-icon id="close-tutorial" style="cursor:pointer;color: white;font-size: 40px;top: 4px;right: -23px;position: absolute;" aria-label="Close">
               close
               <md-tooltip md-direction="bottom"><b style="font-size:13px;">Close Tutorials</b></md-tooltip>
            </md-icon>
         </span>
         <div style="position: relative; padding-bottom: calc(58.46527777777778% + 42px); height: 0;"><iframe src="https://demo.carbone.io/embed/clwrsizfj0vdwdmfhxaeo9n47" title="Studio Tutorials" allow="clipboard-write" frameborder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div>
      </div> 
   </span>
</template>

<style>

   #close-tutorial:focus {
      outline: none;
      box-shadow: none;
   }
   #modal-tutorial {
      visibility:hidden;
      opacity:0;
      animation: fadein 0.2s ease-in forwards;
      display: flex;
      flex-direction: column;
      position: absolute;
      z-index: 100;
      top: 50%;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);
      width: 1100px;
      height: 600px;
      background:white;
      border-radius: 8px;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
   }

   @keyframes fadein{
      0%{
         visibility:hidden;
         opacity: 0;
      }
      100%{
         visibility:visible;
         opacity: 1;
      }
   }

   .modalBackdrop{
      visibility:hidden;
      opacity:0;
      animation: fadein 0.2s ease-in forwards;
      z-index: 99;
      position:fixed;
      top:0;
      left:0;
      width:100vw;
      height:100vh;
      background-color:rgba(0,0,0,0.5);
   }
</style>

<script>

import bus from './bus';
        
export default {
  data () {
    return {
      active : false
    };
  },
  methods : {
    toggle () {
      this.active = !this.active;
      if (window.localStorage && window.localStorage.getItem("tutorial") !== 'closed') {
        bus.$emit('tutorial-closed');
        window.localStorage.setItem("tutorial", "closed");
      }
    }
  },
  mounted () {
    bus.$on('tutorial', () => {
      this.active = true;
    });
  }
};

</script>