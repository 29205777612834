<template>
  <md-dialog ref="DuplicateVersionModal">
    <md-dialog-title>Duplicate a version</md-dialog-title>

    <form v-on:submit.prevent="duplicate()">
      <md-dialog-content>
        <md-input-container>
          <label>Report name</label>
          <md-input placeholder="Report name" name="reportName" id="reportName" :value="current.report.originalFileName"></md-input>
        </md-input-container>
        <md-input-container>
          <label>Version description</label>
          <md-input placeholder="Version description" name="versionDescription" id="versionDescription"></md-input>
        </md-input-container>
      </md-dialog-content>

      <md-dialog-actions>
        <md-button @click="actionDialog('close')">
          Cancel
        </md-button>
        <md-button class="md-raised md-primary" type="submit">
          <md-icon>content_copy</md-icon>
          Duplicate
        </md-button>
      </md-dialog-actions>
    </form>
  </md-dialog>
</template>

<script>
import bus                        from 'src/Components/bus';
import Hermes                     from 'plugins/Hermes';
import { mapGetters, mapActions } from 'vuex';

export default {
  computed : {
    ...mapGetters({
      current : 'current'
    })
  },
  methods : {
    ...mapActions({
      duplicateVersion  : 'duplicateVersion',
      toastSuccess: 'toastSuccess',
      toastError: 'toastError'
    }),

    /**
     * Duplicate a version in local
     * Is call when the form is submitted
     * It check the input and call the request
     */
    duplicate () {
      if (this.current.report === undefined) {
        return;
      }
      var versionDescription = document.getElementById('versionDescription').value;
      var reportName = document.getElementById('reportName').value;

      if (reportName.trim() === '')
        return this.toastError('You must set a report name');
      var data = {
        shareKey           : this.current.version.shareKey,
        filename           : this.current.version.filename,
        versionDescription : versionDescription,
        reportName         : reportName
      };

      // Call the request to duplicate the version
      this.duplicateVersion({
        idVersion : this.current.version.id,
        data      : data
      }).then((msg) => {
        bus.$emit('refreshList');
        this.toastSuccess(msg);
        this.actionDialog('close');
      }, (msg) => {
        this.toastError(msg);
      });
    },

    /**
     * Manage the dialog
     */
    actionDialog (action) {
      var dialog = this.$refs.DuplicateVersionModal;
      switch (action) {
        case 'open':
          dialog.open();
          break;
        case 'close':
          dialog.close();
          break;
        case 'toggle':
          dialog.toggle();
          break;
      }
    }
  },
  mounted () {
    bus.$on('duplicateModal', (action) => {
      this.actionDialog(action);
    });
  }
};
</script>